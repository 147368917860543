import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      meta: {
        name: '',
        requiresAuth: true,
      },
      redirect: {
        path: '/admin',
      },
    },
    {
      path: '/',
      component: () => import('@/views/dashboard/Noauth'),
      children: [
        // Login
        {
          name: 'Login',
          path: 'login',
          component: () => import('@/views/dashboard/pages/Login'),
        },
        // Logout
        {
          name: 'Logout',
          path: 'logout',
          component: () => import('@/views/dashboard/components/core/Logout'),
        },
      ],
    },
    {
      path: '/admin',
      meta: {
        name: '',
        requiresAuth: true,
      },
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Inicio',
          path: 'inicio',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        // Clientes
        {
          name: 'Clientes',
          path: 'clientes',
          component: () => import('@/views/dashboard/clientes/Clientes'),
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.authorized) {
      next()
    } else {
      next('/login')
    }
  } else {
    next()
  }
})

export default router
